<template>
    <div class="school-enroll-container ">
        <div class="enroll-l">
            <div class="enroll-l-title">报名咨询</div>
            <div class="com-enroll-pannel">
                <div class="input-box">
                    <form onsubmit="return false">
                        <div class="row row1">
                            <span>姓名</span>
                            <input type="text" placeholder="请填写您的姓名" title="姓名" v-model="name" />
                        </div>
                        <div class="row row2">
                            <span>手机</span>
                            <input type="text" placeholder="请填写您的手机号" maxlength="11" title="手机号" v-model="tel" />
                        </div>
                        <div class="row row6 row-sms">
                            <span>验证</span>
                            <input type="text" placeholder="请填写短信验证码" maxlength="11" title="短信验证码" v-model="smsCode" />
                            <button type="button" class="btn-get-sms" :disabled="disabled" @click="sendSmsCode">短信验证码</button>
                        </div>
                        <!-- <div class="row row3">
                            <span>驾照</span>
                            <select name="txtCardType">
                                <option value="C1">C1</option>
                                <option value="C2">C2</option>
                                <option value="B1">B1</option>
                                <option value="B2">B2</option>
                                <option value="A1">A1</option>
                                <option value="A2">A2</option>
                                <option value="A3">A3</option>
                            </select>
                        </div> -->
                        <div class="row row4">
                            <span>位置</span>
                            <input type="text" placeholder="所在区域" v-model="address" />
                        </div>
                        <div class="row row5">
                            <button class="submit-btn" @click="submit">提交</button>
                        </div>
                    </form>
                    <!-- <p style="font-size:14px;color:#999;margin-top:5px;">
                        <input type="checkbox" class="check-box" v-model="acceptPolicy">
                        同意<a>《隐私政策》</a>
                        <a>《个人信息保护声明》</a>
                    </p> -->
                </div>
                <div class="captcha-dialog hide">
                    <div class="mengban"></div>
                    <div class="captcha-box">
                        <a class="close">×</a>
                        <p>图片验证</p>
                        <div class="wd-captcha-w"></div>
                    </div>
                </div>
            </div>
            <div class="enroll-l-titletip">留下您的联系方式，不久后会收到来电</div>
        </div>
        <div class="enroll-r">
            <div class="enroll-r-title">—— 报名须知 ——</div>
            <p>1.申请小型汽车、小型自动挡汽车、轻便摩托车准驾车型的，在18周岁以上，70周岁以下。</p>
            <p>2.申请低速载货汽车、三轮汽车、普通三轮摩托车、普通二轮摩托车或者轮式自行机械车准驾车型的，在18周岁以上，60周岁以下。</p>
            <p>3.申请城市公交车、中型客车、大型货车、无轨电车或者有轨电车准驾车型的，在21周岁以上，50周岁以下。</p>
            <p>4.申请牵引车准驾车型的，在24周岁以上，50周岁以下。</p>
            <p>5.申请大型客车准驾车型的，在26周岁以上，50周岁以下。</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import VerifyCode from '@/mixins/verify-code';
import {enroll} from '@/api/training';
import {getCodeApi, registerVerify} from '@/api/user';

export default defineComponent({

    components: {

    },

    mixins: [VerifyCode],

    props: {
        sid: Number
    },

    data: function () {

        return {
            name: '',
            tel: '',
            address: '',
            smsCode: '',
            keyCode: '',
            acceptPolicy: false
        };
    },

    mounted: function () {
        this.getCode();
    },

    methods: {

        getCode() {
            getCodeApi()
                .then(res => {
                    this.keyCode = res.data.key;
                })
                .catch(res => {
                    this.$toast(res);
                });
        },

        sendSmsCode: function () {

            if (!this.tel.trim() || !/^1\d{10}$/.test(this.tel.trim())) {
                this.$toast('请填写正确的手机号');
                return;
            }

            registerVerify({
                phone: this.tel,
                type: 'verify',
                key: this.keyCode,
                code: ''
            })
            .then(res => {
                this.$toast(res.msg);
                this.smsSendInterval();
            })
            .catch(res => {
                this.$toast(res);
                // if (res.data.status === 402) {
                //  this.codeUrl = `${VUE_APP_API_URL}/sms_captcha?key=${this.keyCode}`;
                //  this.isShowCode = true;
                // }
            });
        },

        submit: function () {

            let data = {
                sid: this.sid,
                name: this.name.trim(),
                tel: this.tel.trim(),
                address: this.address.trim(),
                smsCode: this.smsCode,
                smsCodeId: this.smsCodeId
            };

            // if (!this.acceptPolicy) {
            //     this.$toast('为同意隐私策略');
            //     return;
            // }

            if (!data.name) {
                this.$toast('请填写姓名');
                return;
            }

            if (!data.tel || !/^1\d{10}$/.test(data.tel)) {
                this.$toast('请填写正确的手机号');
                return;
            }

            if (!data.smsCode) {
                this.$toast('请填写验证码');
                return;
            }

            enroll(data).then((res) => {
                alert(res.msg);
            });
        }
    }
});
</script>