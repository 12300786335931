<template>
    <div class="school-home-main-container">

       <!--  <div class="school-nav-container ">
            <ul><span class="schoolName">北方驾校</span><a>
                    <li class="active">驾校首页</li>
                </a><a>
                    <li class="">驾校介绍</li>
                </a><a>
                    <li class="">学车班型</li>
                </a><a>
                    <li class="">教练风采</li>
                </a><a>
                    <li class="">训练场</li>
                </a><a>
                    <li class="">班车路线</li>
                </a><a>
                    <li class="">学员评价</li>
                </a><a>
                    <li class="">联系我们</li>
                </a></ul>
        </div> -->

        <div class="school-poster-container">
            <div class="poster-wrap"></div>
            <div class="poster-content">
                <div class="poster-text">以学员为本、全心全意为学员服务</div>
                <div class="poster-sub-text">—— Take the student as this wholeheartedly serves for the student ——</div>
                <div class="poster-btn-group">
                    <div class="group-btn group-l">
                        <div class="icon-wrap"></div>
                        <div class="text-wrap">
                            <b>{{school.tel}}</b>
                            <span>报名热线，欢迎报名学车！</span>
                        </div>
                    </div>
                    <div class="group-btn group-r" @click="consult">
                        <div class="icon-wrap"></div>
                        <div class="text-wrap">
                            <b>免费咨询</b>
                            <span>报名热线，欢迎报名学车！</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="school-items">
            <div class="item">
                <div class="item-title"><span>驾校介绍</span></div>
                <div class="item-content">
                    <div class="school-base-info-container">
                        <div class="base-info-l">
                            <div class="img-wrap show" v-if="school.images">
                                <img :src="school.images[0]">
                                <div class="media-count show">
                                    <i class="img-icon"></i>
                                    <span>{{school.images.length}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="base-info-m">
                            <div class="name">
                                <span>{{school.name}}</span>
                            </div>
                            <ul class="labels">
                                <li v-if="school.pickup">
                                    <span>有接送</span>
                                </li>
                            </ul>
                            <div class="score-student">
                                <div class="score-wrap">
                                    <div class="star-w">
                                        <div class="score" :style="{width: school.rating / 5 + '%'}"></div>
                                    </div>
                                    <span>{{school.rating}}分</span>
                                </div>
                                <div class="student-wrap">
                                    <i></i><span>{{school.students}}名学员</span>
                                </div>
                            </div>
                            <div class="sign-up-phone">报名电话：<span>{{school.tel}}</span></div>
                            <div class="filed">
                                驾校地址：<span>{{school.address}}</span>
                                <!-- <b>[地图]</b> -->
                            </div>
                            <div class="enroll-btn ">免费咨询</div>
                        </div>

                        <!-- <div class="base-info-r">
                            <div class="split-border"></div>
                            <div class="qr-code-wrap">
                                <img />
                                <span>驾校专属题库</span>
                            </div>
                        </div> -->

                        <div class="com-school-map-box">
                            <div class="com-dialog-template hide">
                                <div class="mengban"></div>
                                <div class="table">
                                    <div class="table-cell">
                                        <div class="dialog-box"><a class="close">×</a>
                                            <div class="dialog-content">
                                                <div class="school-map-address"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item"></div>

            <div class="item" v-if="school">
                <div class="item-title">
                    <span>学车班型</span>
                    <!-- <a class="content-more">查看更多 &gt;</a> -->
                </div>
                <div class="item-content">
                    <school-class-list :sid="id" @on-consult="consultClass" />
                </div>
            </div>

            <div class="item">
                <div class="item-title">
                    <span>教练风采</span>
                    <!-- <a class="content-more">查看更多 &gt;</a> -->
                </div>
                <div class="item-content">
                    <coach-list :sid="id" @on-consult="consultCoach" />
                </div>
            </div>

            <!-- <div class="item">
                <div class="item-title"><span>场地信息</span></div>
                <div class="item-content">
                    <div class="school-train-container">
                        <div class="swiper-container1">
                            <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
                                <div class="swiper-slide swiper-slide-active" style="width: 386.667px; margin-right: 20px;"><a>
                                        <li class="no-m-t">
                                            <div class="li-l">
                                                <img src="" alt="">
                                            </div>
                                            <div class="li-r">
                                                <div class="name"><b class="nearest">北方驾校训练场</b><span class="nearest">最近</span></div>
                                                <div class="address"></div>
                                                <div class="distance"><span>1684.0km</span>
                                                    <p>科二、科三</p>
                                                </div>
                                            </div>
                                        </li>
                                    </a></div>
                            </div>
                        </div>
                        <div class="more"></div>
                    </div>
                </div>
            </div> -->

            <div class="item" v-if="school.images && school.images.length > 1">
                <div class="item-title"><span>教学环境</span></div>
                <div class="item-content">
                    <div class="school-photo-container">
                        <div class="photo-wrap">
                            <div class="photo-wrap-wrap">

                                <div class="photo-img " v-for="(img, index) in school.images" :key="index">
                                    <img :src="img">
                                </div>

                            </div>
                            <!-- <div class="more-content">查看更多 &gt;</div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="item enroll-item ">
                <div class="item-content">

                    <school-enroll-form :sid="id" />

                </div>
            </div>

            <!-- <div class="item" style="background:#FFFFFF">
                <div class="item-title"><span>学员评价</span></div>
                <div class="item-content">
                    <school-comments :sid="id" />
                    <a class="content-more">查看全部评价 &gt;</a>
                </div>
            </div> -->

        </div>

        <!-- <div class="school-footer-container">
            <p>驾校地址：{{school.address}}</p>
            <p>报名电话：{{school.tel}}</p>
        </div> -->

    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';

import {useSchoolDetail} from '@/compositions/training-school';
import SchoolClassList from '@/components/school/school-class-list.vue';
import SchoolComments from '@/components/school/school-comments.vue';
import SchoolEnrollForm from '@/components/school/enroll-form.vue';
import CoachList from '@/components/school/coach-list.vue';

export default defineComponent({

    props: {
        id: Number
    },

    components: {
        SchoolClassList,
        // SchoolComments,
        SchoolEnrollForm,
        CoachList
    },

    setup: (props) => {

        const {id} = toRefs(props);

        return useSchoolDetail(id as Ref<number>);
    },

    methods: {
        consult: function (item) {
            console.log(item);
        },

        consultCoach: function (coach) {
            console.log(coach);
        },

        consultClass: function (item) {
            console.log(item);
        }
    }
});
</script>

<style>
.header-background-gray {
    color: #f5f5f5
}

.block-item>.more-btn,
.load-more-btn {
    display: block;
    height: .8rem;
    line-height: .8rem;
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #f5f5f5;
    text-align: center;
    color: #666
}

.t-a-c {
    text-align: center
}

.com-topic-detail>.detail-right>.bottom-bar,
.com-topic-detail>.detail-right>.content .pre,
.com-topic-detail>.detail-right>.top-bar {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-detail,
.com-topic-detail>.detail-right>.bottom-bar>.huifu+.huifu {
    border-right: 1px solid #e9e9e9
}

.com-topic-detail {
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background-color: #f5f5f5
}

.com-topic-detail>.detail-left {
    float: left;
    width: 220px;
    padding: 30px 0 10px
}

.com-topic-detail>.detail-right {
    margin-left: 220px;
    padding: 0 20px;
    background-color: #fff
}

.com-topic-detail>.detail-right>.top-bar {
    height: 40px;
    line-height: 40px;
    color: #999;
    font-size: 12px
}

.com-topic-detail>.detail-right>.top-bar>.publish-time {
    float: left
}

.com-topic-detail>.detail-right>.top-bar>.share {
    float: left;
    margin-left: 30px
}

.com-topic-detail>.detail-right>.top-bar>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.top-bar>.statis {
    float: right
}

.com-topic-detail>.detail-right>.content {
    min-height: 100px;
    padding-top: 10px;
    line-height: 1.5
}

.com-topic-detail>.detail-right>.content p {
    line-height: 28px
}

.com-topic-detail>.detail-right>.content .pre {
    word-wrap: break-word;
    font-size: 18px
}

.com-topic-detail>.detail-right>.content pre.pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap
}

.com-topic-detail>.detail-right>.content>.content-img .img-wrap {
    width: auto;
    max-width: 700px;
    margin: 10px auto;
    text-align: center
}

.com-topic-detail>.detail-right>.content img {
    margin: 10px auto;
    max-width: 700px
}

.com-topic-detail>.detail-right>.button-bar {
    padding: 50px 0 0;
    text-align: center
}

.com-topic-detail>.detail-right>.button-bar>button {
    width: 150px;
    height: 40px;
    margin: 0 10px;
    color: #fff
}

.com-topic-detail>.detail-right>.button-bar>.zan {
    background-color: #7eb4ed
}

.com-topic-detail>.detail-right>.button-bar>.reply {
    background-color: #EE4653
}

.com-topic-detail>.detail-right>.detail-footer {
    padding: 10px 0 0;
    text-align: center;
    color: #999
}

.com-topic-detail>.detail-right>.detail-footer>.zan-wrap {
    display: inline-block;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.detail-footer>.zan-wrap>.author-list {
    display: inline-block;
    max-width: 500px;
    vertical-align: middle;
    color: #7eb4ed;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-detail>.detail-right>.detail-footer>.share {
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px
}

.com-topic-detail>.detail-right>.detail-footer>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.detail-relative-wrap {
    padding: 30px 0 10px
}

.com-topic-detail>.detail-right>.bottom-bar {
    padding: 10px 0 15px;
    font-size: 12px;
    line-height: 1
}

.com-topic-detail>.detail-right>.bottom-bar>.tuijian {
    float: left;
    color: #999
}

.com-topic-detail>.detail-right>.bottom-bar>.tuijian:hover {
    color: #EE4653
}

.com-topic-detail>.detail-right>.bottom-bar>.huifu {
    float: right;
    padding: 0 6px;
    cursor: pointer
}

.com-author>.address,
.com-author>.statis>div>.p2 {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-author>.address>p,
.com-author>.name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-author {
    width: 150px;
    margin: 0 auto
}

.com-author>.img-wrap {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto;
    overflow: hidden
}

.com-author>.img-wrap>.louzhu-tip {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background: url("../../assets/files/13f544edd312e3a4fafdabcb4b8029d5.png") right top no-repeat;
    background-size: 100%
}

.com-author>.img-wrap img {
    display: block;
    width: 100%;
    height: auto;
    border: 4px solid #fff
}

.com-author>.name {
    padding: 10px 0;
    text-align: center;
    font-weight: 700;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-author>.statis {
    padding: 10px 0
}

.com-author>.statis>div {
    display: inline-block;
    width: 33.3%;
    vertical-align: top;
    text-align: center
}

.com-author>.statis>div>.p1 {
    color: #EE4653;
    line-height: 1.5
}

.com-author>.statis>div>.p2 {
    color: #666;
    font-size: 14px
}

.com-author>.statis>div+div {
    border-left: 1px solid #999
}

.com-author>.address {
    padding-top: 5px;
    padding-left: 8px;
    color: #999;
    line-height: 1.5;
    font-size: 12px
}

.com-author>.address>p {
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-author .bar {
    padding-top: 12px
}

.com-author .bar>button {
    display: inline-block;
    width: 100%;
    line-height: 38px;
    border: 1px solid #e9e9e9;
    background-color: #fff
}

.com-author .bar>button:hover {
    color: #EE4653
}

.com-topic-vote>.car-img-wrap {
    position: relative;
    margin: 30px auto 10px;
    max-width: 580px;
    text-align: center
}

.com-topic-vote>.car-img-wrap>.car {
    display: inline-block;
    margin: 0 40px;
    width: 210px;
    vertical-align: top
}

.com-topic-vote>.car-img-wrap>.car>.car-img {
    width: 210px;
    height: 140px;
    margin: 0;
    overflow: hidden;
    background-size: 100%
}

.com-topic-vote>.car-img-wrap>.car>.car-img .car-name {
    line-height: 1.5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-vote>.car-img-wrap>.car>.car-img.transform {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.com-topic-vote>.car-img-wrap>.pk-icon {
    position: absolute;
    width: 80px;
    height: 90px;
    top: 50%;
    left: 50%;
    margin-top: -45px;
    margin-left: -40px;
    background: url("../../assets/files/04df8406d2efc5ccf104e3134fc5b8c7.png") no-repeat;
    background-size: 100%
}

.com-topic-vote>.car-img-wrap>.pk-icon3 {
    margin-top: -70px
}

.com-topic-vote>.statis {
    padding-top: 10px;
    color: #999;
    text-align: center
}

.com-topic-vote>.car-bfb-wrap {
    width: 500px;
    margin: auto
}

.com-topic-vote>.car-bfb-wrap>.row {
    height: 40px;
    line-height: 40px
}

.com-topic-vote>.car-bfb-wrap>.row>.col {
    display: inline-block;
    vertical-align: middle
}

.com-topic-vote>.car-bfb-wrap>.row>.col1 {
    width: 13%;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 {
    width: 65%;
    padding: 10px
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 .tiao1 {
    width: 100%;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 3px
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 .tiao2 {
    height: 100%;
    background-color: #EE4653;
    border-radius: 3px
}

.com-topic-vote>.car-bfb-wrap>.row>.col3 {
    width: 7%;
    text-align: center;
    color: #EE4653
}

.com-topic-vote>.car-bfb-wrap>.row>.col4 {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    background: url("../../assets/files/58d9eeace7a650f77dd383e47255390e.png") center no-repeat;
    background-size: 100%;
    cursor: pointer;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.com-topic-vote>.car-bfb-wrap2 {
    width: 400px;
    margin: auto;
    padding-bottom: 10px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col1 {
    float: left;
    width: 40px;
    height: 40px;
    background: url("../../assets/files/58d9eeace7a650f77dd383e47255390e.png") no-repeat;
    background-size: 100%;
    cursor: pointer
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2 {
    position: relative;
    margin: 15px 48px 0
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bar1 {
    float: left;
    background-color: #EE4653;
    height: 8px;
    border-radius: 16px;
    margin-left: -1px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bar2 {
    float: right;
    background-color: #7eb4ed;
    height: 8px;
    border-radius: 16px;
    margin-right: -1px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bfb-count1 {
    position: absolute;
    left: 0;
    bottom: -6px;
    color: #EE4653
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bfb-count2 {
    position: absolute;
    right: 0;
    bottom: -6px;
    color: #7eb4ed
}

.com-topic-vote>.car-bfb-wrap2>.row>.col3 {
    float: right;
    width: 40px;
    height: 40px;
    background: url("../../assets/files/c16b130dea2edb8a6ca62a22efc35d91.png") no-repeat;
    background-size: 100%;
    cursor: pointer
}

.com-topic-help>.tags-wrap>.budget-tags,
.com-topic-help>.tags-wrap>label {
    vertical-align: top;
    display: inline-block
}

.com-topic-help>.tags-wrap {
    padding-top: 10px
}

.com-topic-help>.tags-wrap>label {
    width: 50px;
    color: #999
}

.com-topic-help>.tags-wrap>.need-tags {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px
}

.com-topic-help>.tags-wrap>.need-tags>span {
    display: inline-block;
    line-height: 1.4;
    margin: 5px;
    padding: 0 5px;
    border: 1px solid;
    border-radius: 8px;
    color: #7eb4ed
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a,
.com-topic-video>.dialog-video-box>.content-box>.content-text {
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-video {
    width: 580px;
    margin: 13px auto
}

.com-topic-video video {
    display: block;
    width: 100%;
    height: auto
}

.com-topic-video>.dialog-video-box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 1000
}

.com-topic-video>.dialog-video-box>.content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 325px;
    height: 171.6px;
    margin-top: -84.5px;
    margin-left: -162.5px;
    background: #fff;
    border-radius: 6.5px;
    padding: 13px 0
}

.com-topic-video>.dialog-video-box>.content-box>.content-text {
    line-height: 32.5px;
    border-bottom: 1.3px solid #e9e9e9;
    padding: 13px 13px 26px 52px
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar {
    text-align: center
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a {
    display: inline-block;
    line-height: 52px;
    width: 50%;
    text-align: center
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a:first-child {
    border-right: 1.3px solid #e9e9e9
}

.com-topic-video>.iphone-bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent
}

.com-topic-audio .audio-wrap {
    display: -webkit-box
}

.com-topic-audio .audio-wrap .voice-bg {
    display: block;
    width: 13px;
    height: 18px;
    background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") left center no-repeat;
    background-size: 100% 100%
}

.com-topic-audio .audio-wrap .audio-bg {
    display: block;
    width: 93px;
    height: 28px;
    padding: 5px 0 0 12px;
    background-size: 13px 18px;
    background-color: #8cdffe;
    border-radius: 5px
}

.com-topic-audio .audio-wrap .audio-scd {
    display: block;
    line-height: 30px;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    margin-left: 12px;
    font-family: microsoft yahei
}

@-webkit-keyframes zoomIn {
    0% {
        background: url("../../assets/files/9f6c57d097e320b65591edf29e2c083d.png") center center no-repeat;
        background-size: 100% 100%
    }

    50% {
        background: url("../../assets/files/2b7c181271a4a9db31594fc070e282b5.png") center center no-repeat;
        background-size: 100% 100%
    }

    100% {
        background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") center center no-repeat;
        background-size: 100% 100%
    }
}

@keyframes zoomIn {
    0% {
        background: url("../../assets/files/9f6c57d097e320b65591edf29e2c083d.png") center center no-repeat;
        background-size: 100% 100%
    }

    50% {
        background: url("../../assets/files/2b7c181271a4a9db31594fc070e282b5.png") center center no-repeat;
        background-size: 100% 100%
    }

    100% {
        background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") center center no-repeat;
        background-size: 100% 100%
    }
}

.com-topic-audio .zoomIn {
    -webkit-animation: zoomIn 1s infinite both;
    animation: zoomIn 1s infinite both
}

.com-topic-audio>.audio-wrap {
    margin-top: 20px;
    margin-bottom: 20px
}

.com-topic-audio>.audio-wrap>.audio-bg {
    height: 38px;
    cursor: pointer
}

.com-topic-audio>.audio-wrap>.audio-bg .voice-bg {
    margin: 5px 0 0 12px
}

.com-topic-audio>.audio-wrap>.audio-scd {
    line-height: 38px
}

.com-topic-work .work-container {
    height: 100%;
    position: relative;
    padding: 0
}

.com-topic-work .work-container ul {
    border: 1px solid #EEE
}

.com-topic-work .work-container li {
    display: -webkit-box;
    border-bottom: 1px solid #EEE
}

.com-topic-work .work-container li.first {
    padding: 9.28px 9.28px 9.28px 5.8px
}

.com-topic-work .work-container li.first a {
    display: -webkit-box;
    -webkit-box-flex: 1;
    color: #333;
    text-decoration: none
}

.com-topic-work .work-container li.first img {
    display: block;
    width: auto;
    height: 53px;
    margin: 0
}

.com-topic-work .work-container li.first .car {
    -webkit-box-flex: 1;
    font-size: 15.08px;
    line-height: 53.36px;
    padding-left: 17.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5.8px
}

.com-topic-work .work-container li.first .arrow {
    width: 10.44px;
    background: url("../../assets/files/6a3eb8a10a1a27d7764874cfd4cbe819.png") right center no-repeat;
    background-size: 100%
}

.com-topic-work .work-container li div.title {
    color: #333;
    text-align: center;
    padding: 16.24px 13.92px;
    width: 25%;
    font-size: 16.24px;
    line-height: 20.88px;
    border-right: 1px solid #EEE;
    display: flex;
    align-items: center;
    justify-content: center
}

.com-topic-work .work-container li div.desc {
    color: #333;
    text-align: center;
    padding: 16.24px 13.92px;
    -webkit-box-flex: 1;
    font-size: 15.08px;
    line-height: 20.88px
}

.com-topic-work .work-container .content {
    margin-top: 17.4px
}

.com-topic-work .work-container .content header {
    font-size: 17.4px;
    line-height: 34.8px
}

.com-topic-work .work-container .content p {
    color: #999;
    font-size: 15.08px;
    line-height: 23.2px
}

.com-topic-work .work-container .content img {
    display: block;
    width: 100%;
    margin: 11.6px 0
}

.com-topic-work pre {
    white-space: pre-wrap;
    word-wrap: break-word
}

.com-detail-content-quote>.row {
    display: block;
    margin: 20px 0 10px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 4px
}

.com-detail-content-quote>.row>.img-w {
    display: inline-block;
    vertical-align: middle;
    width: 102px;
    height: 70px;
    overflow: hidden
}

.com-detail-content-quote>.row>.img-w img {
    display: block;
    width: 100%;
    height: auto;
    border: none;
    margin: 0 !important
}

.com-detail-content-quote>.row>.content-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    width: 760px;
    line-height: 1.4;
    text-decoration: underline
}

.com-topic-koubei .kb-container {
    height: 100%;
    position: relative;
    padding: 0
}

.com-topic-koubei .kb-report-wrap {
    height: 243.6px;
    display: -webkit-box;
    position: relative;
    -webkit-box-align: end
}

.com-topic-koubei .kb-report {
    position: relative;
    height: 203px
}

.com-topic-koubei .kb-report .report-bg {
    position: absolute;
    height: 174px;
    width: 100%;
    top: 0;
    right: 0;
    left: 0
}

.com-topic-koubei .kb-report .report-bg div {
    margin-top: 34.8px;
    border-top: 1px solid rgba(0, 0, 0, .05)
}

.com-topic-koubei .kb-report .report-col {
    border-top: 1px solid rgba(0, 0, 0, .05);
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    height: 174px;
    display: -webkit-box;
    position: relative;
    -webkit-box-align: end
}

.com-topic-koubei .kb-report .report-col .col-item {
    position: relative;
    -webkit-box-flex: 1;
    margin: 0 25px;
    height: 65%;
    background: -webkit-linear-gradient(top, #7eb4ed 0, #7eb4ed 100%);
    border-radius: 6.96px 6.96px 0 0
}

.com-topic-koubei .kb-report .report-col .col-item span {
    position: absolute;
    top: -23.2px;
    left: -4.64px;
    color: rgba(0, 0, 0, .4);
    min-width: 23.2px;
    text-align: center
}

.com-topic-koubei .kb-report .report-label {
    display: -webkit-box
}

.com-topic-koubei .kb-report .report-label span {
    display: block;
    -webkit-box-flex: 1;
    text-align: center;
    font-size: 13.92px;
    color: rgba(0, 0, 0, .4);
    line-height: 17.4px;
    padding: 11.6px 0 0
}

.com-topic-koubei .kb-report .report-label span:last-child {
    -webkit-box-flex: .5
}

.com-topic-koubei .kb-car-series {
    height: 47.56px;
    line-height: 47.56px;
    padding: 0 17.4px;
    margin-top: 41.76px;
    border: 1px solid rgba(0, 0, 0, .05);
    font-size: 16.24px;
    color: #353D51;
    font-weight: 700
}

.com-topic-koubei .kb-car-series a {
    text-decoration: none;
    color: #666
}

.com-topic-koubei .kb-car-series p {
    line-height: 47px !important;
    padding-right: 18.56px;
    background: url("../../assets/files/d75e153a8c105438f64aea20e2245bfa.png") right center no-repeat;
    background-size: 10.44px 16.24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.com-topic-koubei .kb-car-info {
    padding: 0 17.4px;
    background: #F7F7F7;
    margin-top: 13.92px
}

.com-topic-koubei .kb-car-info p {
    display: -webkit-box;
    padding: 11.6px 0 10.44px;
    border-bottom: 1px solid rgba(0, 0, 0, .04)
}

.com-topic-koubei .kb-car-info p span {
    font-size: 16.24px;
    line-height: 23.2px;
    display: block;
    width: 50%;
    color: #666
}

.com-topic-koubei .kb-car-info p span.single {
    width: 100%
}

.com-topic-koubei .kb-car-info p span i {
    font-size: 13.92px
}

.com-topic-koubei .kb-car-comm {
    display: -webkit-box
}

.com-topic-koubei .kb-car-comm div {
    padding: 4.64px 20.88px 0 0
}

.com-topic-koubei .kb-car-comm div label {
    display: block;
    padding: 20.88px 0 0;
    font-size: 13.92px;
    line-height: 16.24px
}

.com-topic-koubei .kb-car-comm p {
    font-size: 17.4px;
    color: #666;
    line-height: 24.36px;
    word-break: break-all;
    -webkit-box-flex: 1
}

.com-topic-koubei .kb-car-merit {
    margin-top: 30.16px
}

.com-topic-koubei .kb-car-merit div label {
    background: url("../../assets/files/142379d94c3a4ddf1f22dfd3fc592ed5.png") top center no-repeat;
    background-size: 18.56px 18.56px;
    color: #FFAB49
}

.com-topic-koubei .kb-car-defect {
    padding: 16.24px 0 30.16px;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.com-topic-koubei .kb-car-defect div label {
    background: url("../../assets/files/22f96ddf4319d6de9e292cc95f1911c8.png") top center no-repeat;
    background-size: 18.56px 18.56px;
    color: #3DA7FF
}

.com-topic-koubei .kb-car-intro {
    margin-top: 27.84px
}

.com-topic-koubei .kb-car-intro .intro-hd label {
    font-size: 17.4px;
    color: #333;
    line-height: 23.2px;
    font-weight: 700
}

.com-topic-koubei .kb-car-intro>p {
    display: inline-block;
    vertical-align: top
}

.com-topic-koubei .kb-car-intro .intro-hd span {
    font-size: 16.24px;
    line-height: 23.2px;
    color: #999
}

.com-topic-koubei .kb-car-intro .intro-con {
    padding-left: 4.64px;
    font-size: 17.4px;
    color: #666;
    line-height: 24.36px
}

.com-topic-koubei .kb-img-list {
    overflow: hidden;
    width: 100%;
    margin-top: 27.84px;
    margin-bottom: 18.56px
}

.com-topic-koubei .kb-img-list img {
    display: block;
    width: 100%
}

.com-topic-koubei .kb-report {
    width: 500px;
    margin: 0 auto
}

.com-topic-comment>ul>li,
.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu+.huifu {
    border-right: 1px solid #e9e9e9
}

.com-topic-comment>ul>li {
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background-color: #f5f5f5
}

.com-topic-comment>ul>li>.fg {
    height: 4px;
    background-color: #e9e9e9
}

.com-topic-comment>ul>li>.detail-left {
    float: left;
    width: 220px;
    padding: 30px 0 10px
}

.com-topic-comment>ul>li>.detail-right {
    position: relative;
    min-height: 290px;
    margin-left: 220px;
    padding: 0 20px 50px;
    background-color: #fff
}

.com-topic-comment>ul>li>.detail-right>.top-bar {
    height: 40px;
    line-height: 40px;
    color: #999;
    font-size: 12px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-comment>ul>li>.detail-right>.content>.content-text,
.com-topic-comment>ul>li>.detail-right>.zuijia:before {
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.publish-time {
    float: left
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.footer-name {
    float: right
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.share {
    float: left;
    margin-left: 30px
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.statis {
    float: right
}

.com-topic-comment>ul>li>.detail-right>.content {
    padding-top: 10px;
    line-height: 1.5
}

.com-topic-comment>ul>li>.detail-right>.content p {
    line-height: 28px
}

.com-topic-comment>ul>li>.detail-right>.content>.content-img .img-wrap {
    width: auto;
    max-width: 700px;
    margin: 10px auto;
    text-align: center
}

.com-topic-comment>ul>li>.detail-right>.content img {
    margin: 10px auto;
    max-width: 700px
}

.com-topic-comment>ul>li>.detail-right>.zuijia {
    background-color: #f5f5f5;
    padding: 0 10px 10px
}

.com-topic-comment>ul>li>.detail-right>.zuijia:before {
    display: block;
    height: 30px;
    margin-bottom: 10px;
    padding-left: 50px;
    background: url("../../assets/files/ba1d1c2af42dc8104387f0cdadb5e151.png") left center no-repeat;
    background-size: auto 100%;
    content: '最佳答案';
    line-height: 30px;
    color: #EE4653
}

.com-topic-comment>ul>li>.detail-right>.quote-wrap {
    min-height: auto;
    margin: 10px 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #e9e9e9
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    padding: 10px 0 15px;
    font-size: 12px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.tuijian {
    float: left;
    max-width: 400px;
    color: #999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu:hover,
.com-topic-comment>ul>li>.detail-right>.bottom-bar>.tuijian:hover {
    color: #EE4653
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu {
    float: right;
    padding: 0 6px;
    cursor: pointer
}

.com-topic-comment>.footer-pager {
    padding: 20px 0;
    text-align: left
}

.com-detail-footer-items>.com-part>.part-title,
.com-detail-footer-items>.com-part>.part-title .more {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-detail-footer-items>.com-part {
    margin: 40px 0
}

.com-detail-footer-items>.com-part>.part-title {
    line-height: 22px;
    margin-bottom: 10px;
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #e9e9e9
}

.com-detail-footer-items>.com-part>.part-title .more {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    color: #999
}

.com-detail-footer-items>.com-part>.part-title .more:hover {
    color: #37B5F8
}

.com-side-com-list-col3>.list-w {
    margin: 0 -20px
}

.com-side-com-list-col3>.list-w>li {
    float: left;
    width: 33.3%;
    margin: 10px 0;
    padding: 0 20px;
    color: #666
}

.com-side-com-list-col3>.list-w>li>a {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-side-com-list-col3>.list-w>li>a>.dian {
    padding-right: 5px
}

.common-paging>ul>li.pre {
    background: url("../../assets/files/405fee75f912878a3a64cb3185510e6f.png") center no-repeat
}

.common-paging>ul>li.next {
    background: url("../../assets/files/0d6cea75496cac7ee3a629e5369cb9d7.png") center no-repeat
}

.common-paging>ul>li.disabled.pre {
    background: url("../../assets/files/e564bffffcfb26636113cb226064784e.png") center no-repeat
}

.common-paging>ul>li.disabled.next {
    background: url("../../assets/files/6fcaa9f4e4f732405a58f524004e8e92.png") center no-repeat
}

.detail>.topic-pager>.right>button,
.detail>.topic-title,
.detail>.topic-title>.only-author {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.detail {
    margin-top: 20px
}

.detail>.topic-pager {
    margin-left: -6px;
    padding-bottom: 10px
}

.detail>.topic-pager>.right>button {
    margin-top: 8px;
    margin-left: 6px;
    width: 80px;
    line-height: 30px;
    background-color: #EE4653;
    color: #fff;
    font-size: 14px
}

.detail>.topic-pager>.right>button+button {
    background-color: #f90
}

.detail>.topic-pager.bottom {
    padding-top: 10px;
    padding-bottom: 0
}

.detail>.topic-title {
    position: relative;
    line-height: 38px;
    padding-left: 20px;
    border: 1px solid #e9e9e9;
    font-size: 16px;
    font-weight: 700
}

.detail>.topic-title>.shoucang {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 42px;
    height: 15px;
    margin-left: 5px;
    background: url("../../assets/files/5630be4dd152aa14fbe2283e975b1ac0.png");
    vertical-align: middle;
    cursor: pointer
}

.detail>.topic-title>.only-author {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400
}

.detail>.topic-title>h1 {
    display: inline-block;
    font-size: inherit;
    font-weight: inherit
}

.detail>.topic-hot {
    padding-top: 30px
}

.detail .content-link {
    color: #7eb4ed
}

.detail .content-link:hover {
    color: #7eb4ed;
    text-decoration: underline
}

.detail .com-jiaxiao-list-random {
    width: auto
}

.detail .com-jiaxiao-list-random>.list-w {
    margin-left: -150px
}

.detail .com-jiaxiao-list-random>.list-w>li {
    padding-left: 150px;
    margin: 0
}

.layout-user {
    height: 34px;
    line-height: 20px;
    padding: 7px 0;
    background: #f5f5f5;
    color: #999
}

.layout-user>.content .left-link>li .tiku-mune ul>li a.active,
.layout-user>.content .left-link>li .tiku-mune ul>li a:hover,
.layout-user>.content .right-link .active,
.layout-user>.content .right-link a:hover {
    color: #37B5F8
}

.layout-user>.content .city {
    padding-left: 15px;
    background: url("../../assets/files/1272afef1a66936bae7d785d24e4b319.png") left center no-repeat;
    cursor: pointer
}

.com-top-user-city .city {
    padding-left: 15px;
    background: url("../../assets/files/eae9b23cf7983349005bb5e7e771478a.png") left center no-repeat;
    cursor: pointer
}

.common-city-selection>.list>ul>li>.item>ul {
    padding-left: 58px
}

.common-city-selection-dialog>.close {
    width: 25px;
    height: 25px;
    background: url("../../assets/files/a7474b44821b980050ffead66d07dd38.png") center no-repeat;
    right: 0;
    top: 0;
    cursor: pointer;
    position: absolute;
    display: block
}

.layout-navigation>.navigation-top>.logo-w .logo {
    display: block;
    width: 207px;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    background: url("../../assets/files/faab6749bd917811e688ac5d5bc2b782.png") left center no-repeat
}

.layout-navigation>.navigation-top .search-w button {
    position: absolute;
    top: 7px;
    right: 5px;
    height: 29px;
    width: 30px;
    background: url("../../assets/files/988d5f1c9d31781584e82d0ecad4f582.png") center no-repeat;
    border: none
}

.layout-navigation>.navigation-content>.nav-w>li {
    display: inline-block;
    padding: 0;
    vertical-align: top;
    width: 107px;
    text-align: center
}

.layout-navigation2>.logo-w .logo {
    display: block;
    width: 207px;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    background: url("../../assets/files/5309392905c9eaaccdc170f078756f78.png") left center no-repeat
}

.layout-navigation2>.nav-w .search-w button {
    position: absolute;
    top: 2px;
    right: 5px;
    height: 29px;
    width: 30px;
    background: url("../../assets/files/71073a79ef0afc287a4030ad69dfcdce.png") center no-repeat;
    border: none
}

.layout-footer>.content .footer3 {
    height: 108px;
    width: 1200px;
    padding-top: 25px;
    margin: 0 auto;
    padding-bottom: 18px;
    overflow: hidden
}

.layout-footer>.content .footer3 .left {
    float: left;
    margin-left: 180px;
    padding-right: 70px;
    border-right: 1px solid #666;
    width: 232px;
    height: 108px
}

.layout-footer>.content .footer3 .left a {
    display: block;
    padding-top: 15px;
    height: 56px
}

.layout-footer>.content .footer3 .right {
    float: left;
    height: 108px;
    margin-left: 70px
}

.com-dialog-growUp .growUp {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 6rem;
    height: 6rem;
    border-radius: 8px;
    background: url("../../assets/files/fe1205c93682f15045fb62830fb73483.png") center .34rem no-repeat #fff;
    background-size: 3.72rem
}

.com-enroll-pannel .input-box>form>.row .submit-btn.loading {
    background: url("../../assets/files/2e15855e2d3012e2f442c66c952800a9.gif") center no-repeat;
    background-size: contain;
    cursor: default;
    color: transparent
}

.com-dialog-enroll-freephone .dialog-regist-box>.row-tip {
    margin-top: -5px;
    color: #999;
    text-align: center
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-login .other-login>.type .qq {
    width: 44px;
    height: 44px;
    background: url("../../assets/files/8f8a1b78796d67a65e2de8379c81b4ee.png") center center no-repeat;
    background-size: 44px 44px;
    display: block;
    margin: 0 auto
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-coach>.login-code>.img {
    background: url("../../assets/files/7d633be84261a709f4f307c4e7dab942.png") left top no-repeat;
    background-size: 255px 48px;
    position: absolute;
    left: 0;
    top: 0;
    width: 255px;
    height: 48px;
    z-index: 2
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-coach>.login-code>.code {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 200px;
    background: url("../../assets/files/3476971d4d13a3421c9619ec7ce84e5e.png") center center no-repeat;
    background-size: 174px 174px;
    border: 1px solid #eee
}

.com-login-pannel .input-box>form>.row select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../../assets/files/4047b1ec9715fd3a9818df3dde9a3cf1.png") right 10px center no-repeat #fff
}

.com-login-pannel .input-box>form>.row .submit-btn.loading {
    background: url("../../assets/files/b0438773813bf9a2eabe20147a3f29e2.gif") center no-repeat;
    background-size: contain;
    cursor: default;
    color: transparent
}

.com-dialog-login-share .dialog-login-box .other-login-type>.qq {
    width: 44px;
    height: 44px;
    background: url("../../assets/files/f4078891d88bdf4b07ebb580f72840c4.png") center center no-repeat;
    background-size: 44px 44px;
    display: block;
    margin: 20px auto 0
}

.com-login-share .loading {
    min-width: 300px;
    min-height: 180px;
    margin: 0 auto;
    background: url("../../assets/files/85f1c93260c1a7335a6e30ce1fe0f5b2.gif") center no-repeat;
    background-size: 100px
}

.com-footer-flex-panel {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    z-index: 0
}

.com-footer-flex-panel>.content-box {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    z-index: 3
}

.com-footer-flex-panel>.content-box>.flex-close {
    position: absolute;
    top: 45px;
    right: 100px;
    height: 32px;
    width: 32px;
    line-height: 30px;
    border: none;
    background: url("../../assets/files/620b6e8cf3a4e36deba36f5e8f78fbe5.png") center no-repeat;
    font-size: 32px;
    color: #999;
    text-align: center;
    cursor: pointer
}

.com-footer-flex-panel>.content-box>.bg-img {
    position: absolute;
    top: 0;
    left: 90px;
    width: 160px;
    height: 125px;
    background: url("../../assets/files/11d274126fb4a3ce2876037345e1954d.png") center no-repeat;
    background-size: 100%
}

.baidu-search-box input {
    line-height: 24px;
    padding: 0 20px 0 5px;
    background: url("../../assets/files/9b0522d7a24f31d2702e7baa8b575e94.png") right 5px center no-repeat;
    border-color: #37B5F8
}

.com-appdown-dialog>.dialog-box>.dialog-content>.erwm {
    width: 296px;
    height: 296px;
    margin: 20px auto;
    background: url("../../assets/files/dcd41a46d528d96dc110be54407faf44.png") center no-repeat;
    background-size: 80% auto;
    border: 1px solid #e9e9e9;
    border-radius: 6px
}

.com-appdown-dialog>.dialog-box>.btn-dialog-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background: url("../../assets/files/d381506045f2d8582bdf00c0d5b09d45.jpg") center no-repeat;
    background-size: 40%;
    border: 1px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer
}

.com-dialog-first-visited2>.content-box>.content {
    height: 30px;
    line-height: 30px;
    padding-left: 25px;
    background: url("../../assets/files/c454c61f8db82676e5a7d815ac0458e5.png") left center no-repeat;
    background-size: 22px 15px;
    font-size: 18px
}

.com-dialog-first-visited3 .visited-content-box>.item-left>.row-city .city-name {
    padding-right: 20px;
    background: url("../../assets/files/ac7a65de254ed4b793fec8e915767497.png") right center no-repeat;
    color: #37B5F8;
    cursor: pointer;
    font-size: 14px
}

.com-dialog-first-visited3 .visited-content-box>.item-left>.row-baomin .select-baomin {
    width: 260px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-left: 0;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    outline: 0;
    -webkit-appearance: none;
    background: url("../../assets/files/b202869db721d36e059a395366ec278e.png") right 10px center no-repeat #fff
}

.com-dialog-first-visited4 .visited-content-box>.item-left>.row-city .city-name {
    padding-right: 20px;
    background: url("../../assets/files/0e4926aeb52a2955cd85b7faeed2aa33.png") right center no-repeat;
    color: #37B5F8;
    cursor: pointer;
    font-size: 14px
}

.com-dialog-first-visited4 .visited-content-box>.item-left>.row-baomin .select-baomin {
    width: 260px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-left: 0;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    outline: 0;
    -webkit-appearance: none;
    background: url("../../assets/files/026013145c714bb61581f2fd4df84a5d.png") right 10px center no-repeat #fff
}

body,
html {
    overflow-x: hidden
}

.container .none-data-block:before {
    display: block;
    content: '';
    width: 100%;
    height: 2.4rem;
    background: url("../../assets/files/12269a28a995a89f8407725fff30cb36.png") center top no-repeat;
    background-size: 2.8rem
}

.container .page-empty-data span:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: url("../../assets/files/12269a28a995a89f8407725fff30cb36.png") center 30px no-repeat;
    background-size: 280px
}

.container .no-data {
    margin: 30px;
    background: url("../../assets/files/b12469ee38c879f3d0f59dc3392fe978.png") center top no-repeat;
    background-size: 50px 50px;
    padding-top: 60px;
    text-align: center;
    color: #999
}

.container .star-w-s {
    width: 90px;
    height: 20px;
    margin: 10px 0;
    background: url("../../assets/files/d59d67daf676ffce9e157bd3a0634dee.png") left center no-repeat
}

.container .star-w-s>.bfb {
    height: 100%;
    background: url("../../assets/files/07c954300da670874111fd227ae902fb.png") left center no-repeat
}

.container .star-w-m {
    width: 110px;
    height: 20px;
    margin: 10px 0;
    background: url("../../assets/files/e7754c64750c1cb6b742ea4b10dac05a.png") left center no-repeat
}

.container .star-w-m>.bfb {
    height: 100%;
    background: url("../../assets/files/7c543ae5cb114b37005a31933ba1af4a.png") left center no-repeat
}

.school-new-home-school-home .layout-article {
    width: auto;
    margin: 0
}

.school-home-main-container {
    width: 100%;
    min-width: 1440px
}

.school-home-main-container .school-items .item:nth-child(even) {
    background-color: #F2F3F7
}

.school-home-main-container .school-items .item:nth-child(odd) {
    background-color: #fff
}

.school-home-main-container .school-items .item .item-title {
    padding-top: 50px;
    padding-bottom: 30px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between
}

.school-home-main-container .school-items .item .item-title>span {
    width: 136px;
    height: 45px;
    font-size: 34px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: 700;
    color: #41464f;
    line-height: 45px;
    position: relative
}

.school-home-main-container .school-items .item .item-title>span::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    width: 24px;
    height: 5px;
    background: #41464f
}

.school-home-main-container .school-items .item .item-title .content-more {
    cursor: pointer;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #666;
    align-self: flex-end
}

.school-home-main-container .school-items .item .item-title .content-more:hover {
    color: #5771C1
}

.school-home-main-container .school-items .item .item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px
}

.school-home-main-container .school-items .item .item-content .content-more {
    margin-top: 30px
}

.school-home-main-container .school-items .advantage-item .item-content {
    padding-bottom: 0
}

.school-home-main-container .school-items .item.enroll-item {
    background-image: url("../../assets/files/1a8a68d0899ed1ec3770c892711d99e5.png")
}

.school-home-main-container .school-items .item.enroll-item .item-content {
    padding: 50px 0
}

.school-home-main-container .school-items .item.enroll-item.black-gold {
    background-image: url("../../assets/files/6b5b81c60d18079ac274b4c4ff789a9e.png")
}

.school-home-main-container .school-items .school-introduce {
    width: 100%;
    background-color: #32406B;
    display: flex;
    justify-content: center
}

.school-home-main-container .school-items .school-introduce ul.school-introduce-wrap {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 180px
}

.school-home-main-container .school-items .school-introduce ul.school-introduce-wrap>li {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.school-home-main-container .school-items .school-introduce ul.school-introduce-wrap>li p {
    display: flex;
    align-items: flex-end;
    font-size: 15px;
    line-height: 20px;
    color: #fff
}

.school-home-main-container .school-items .school-introduce ul.school-introduce-wrap>li p.intro-t {
    margin-top: 10px
}

.school-home-main-container .school-items .school-introduce ul.school-introduce-wrap>li p b {
    font-size: 52px;
    line-height: 61px;
    font-weight: 400
}

.school-home-main-container .school-items .school-introduce ul.school-introduce-wrap>li p span {
    margin-left: 2px;
    margin-bottom: 10px
}

.school-home-main-container .school-items .school-advantage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-size: 100% 100%;
    background-image: url("../../assets/files/1a8a68d0899ed1ec3770c892711d99e5.png")
}

.school-home-main-container .school-items .school-advantage-container img {
    cursor: pointer;
    width: 1199px;
    height: 159px;
    background: 0 0
}

.school-header-container {
    width: 100%
}

.school-header-container .school-header-wrap {
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-header-container .header-top-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6
}

.school-header-container .header-logo-wrap {
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-header-container .header-top {
    width: 1200px;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.school-header-container .top-l {
    font-size: 15px;
    line-height: 21px;
    color: #333
}

.school-header-container .top-l>span {
    color: #32406B
}

.school-header-container .top-r {
    color: #2E4062;
    font-size: 15px;
    line-height: 21px
}

.school-header-container .top-r>span {
    cursor: pointer
}

.school-header-container .top-r>span:hover {
    color: #37B5F8
}

.school-header-container .top-r>a {
    margin-right: 20px
}

.school-header-container .header-logo {
    width: 1200px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.school-header-container .header-logo .logo-l {
    width: 138px;
    height: 70px
}

.school-header-container .header-logo .logo-l img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.school-header-container .header-logo .logo-r {
    min-width: 171px;
    height: 45px;
    display: flex;
    align-items: center
}

.school-header-container .header-logo .logo-r .logo-r-l .img-wrap {
    width: 49px;
    height: 49px;
    background-size: 100% 100%;
    background-image: url("../../assets/files/b852930ea4fde81488abfb387128a24e.png")
}

.school-header-container .header-logo .logo-r .logo-r-r {
    margin-left: -15px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-header-container .header-logo .logo-r .logo-r-r>span {
    font-size: 14px;
    line-height: 19px;
    color: #32406B
}

.school-header-container .header-logo .logo-r .logo-r-r>b {
    font-size: 24px;
    height: 28px;
    color: #32406B
}

.school-nav-container {
    width: 100%;
    background-color: #32406B;
    display: flex;
    justify-content: center
}

@font-face {
    font-family: ysb;
    src: url("../../assets/files/0ac737bd5d7e5eace3be79ffc46f68ca.ttf")
}

.school-nav-container>ul {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    background-color: #32406B
}

.school-nav-container>ul .schoolName {
    font-size: 34px;
    font-family: ysb;
    color: #fff;
    line-height: 44px;
    margin-right: 73px
}

.school-nav-container>ul li {
    width: 90px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #fff
}

.school-nav-container>ul li.active {
    background-color: #5771C1
}

.school-nav-container.black-gold {
    background: #2e2e2e
}

.school-nav-container.black-gold .schoolName {
    color: #ffcb73
}

.school-nav-container.black-gold .active {
    background: #ffcb73;
    color: #000
}

.school-nav-container.black-gold ul {
    background: #2e2e2e
}

.school-poster-container {
    position: relative
}

.school-poster-container .poster-wrap {
    width: 100%;
    height: 520px;
    background-image: url("../../assets/files/5f811c8b477d656f8d6bb9f2058a57dd.png");
    background-size: 100% 100%
}

.school-poster-container .poster-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-poster-container .poster-content .poster-text {
    margin-top: 120px;
    font-size: 52px;
    color: #fff;
    font-weight: 700;
    line-height: 69px
}

.school-poster-container .poster-content .poster-sub-text {
    margin-top: 15px;
    font-size: 20px;
    line-height: 27px;
    color: rgba(255, 255, 255, .5)
}

.school-poster-container .poster-content .poster-btn-group {
    margin-top: 120px;
    display: flex
}

.school-poster-container .poster-content .poster-btn-group .group-btn {
    width: 600px;
    height: 130px;
    display: flex;
    align-items: center;
    background-color: #fff;
    cursor: pointer
}

.school-poster-container .poster-content .poster-btn-group .group-btn .icon-wrap {
    margin-left: 80px;
    margin-right: 40px;
    width: 70px;
    height: 70px;
    background-size: 100% 100%;
    background-image: url("../../assets/files/3bae9d3ae5a9640c1fe89197ed68e6c9.gif")
}

.school-poster-container .poster-content .poster-btn-group .group-btn .text-wrap {
    display: flex;
    flex-direction: column;
    color: #B2976C
}

.school-poster-container .poster-content .poster-btn-group .group-btn .text-wrap>b {
    font-weight: 400;
    font-size: 26px;
    line-height: 35px
}

.school-poster-container .poster-content .poster-btn-group .group-btn .text-wrap>span {
    font-size: 16px;
    line-height: 21px
}

.school-poster-container .poster-content .poster-btn-group .group-btn.group-l {
    cursor: text;
    background-color: #B2976C
}

.school-poster-container .poster-content .poster-btn-group .group-btn.group-l .text-wrap {
    color: #fff
}

.school-poster-container .poster-content .poster-btn-group .group-btn.group-l .icon-wrap {
    background-image: url("../../assets/files/31b068700cddabfbf69b59a154635b17.png")
}

.school-base-info-container {
    width: 1200px;
    height: 300px;
    border: 1px solid #EAEAEA;
    display: flex;
    align-items: center
}

.school-base-info-container .base-info-l {
    margin-left: 30px;
    width: 328px;
    height: 240px
}

.school-base-info-container .base-info-l .img-wrap {
    position: relative;
    width: 100%;
    height: 100%
}

.school-base-info-container .base-info-l .img-wrap .play-icon {
    cursor: pointer;
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: url("../../assets/files/1bf0361995ad41ceba36836568d20ede.png") left center no-repeat;
    background-size: 100% 100%
}

.school-base-info-container .base-info-l .img-wrap .media-count {
    position: absolute;
    right: 10px;
    bottom: 10px;
    height: 24px;
    background-color: rgba(0, 0, 0, .6);
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    cursor: pointer
}

.school-base-info-container .base-info-l .img-wrap .media-count i {
    display: block;
    width: 20px;
    height: 20px;
    background-size: 100% 100%
}

.school-base-info-container .base-info-l .img-wrap .media-count i.video-icon {
    background-image: url("../../assets/files/940628d5043cf67d25863b6d5f5b9a89.png")
}

.school-base-info-container .base-info-l .img-wrap .media-count i.img-icon {
    background-image: url("../../assets/files/e2989cd09d296067929e8f1516844aed.png")
}

.school-base-info-container .base-info-l .img-wrap .media-count span {
    margin-left: 2px;
    color: #fff;
    font-size: 15px;
    line-height: 20px
}

.school-base-info-container .base-info-l .hide {
    display: none
}

.school-base-info-container .base-info-l .show {
    display: block
}

.school-base-info-container .base-info-l video {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    outline: 0;
    background-color: #000
}

.school-base-info-container .base-info-l img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.school-base-info-container .base-info-m {
    flex: 1;
    margin-left: 30px;
    display: flex;
    flex-direction: column
}

.school-base-info-container .base-info-m .name {
    margin-top: 6px;
    display: flex;
    align-items: center
}

.school-base-info-container .base-info-m .name>span {
    font-size: 24px;
    line-height: 31px;
    color: #333;
    margin-right: 8px;
    font-weight: 700
}

.school-base-info-container .base-info-m .name>img {
    width: 20px;
    height: 20px;
    margin-right: 4px
}

.school-base-info-container .base-info-m ul.labels {
    align-self: flex-start;
    min-width: 300px;
    padding-bottom: 16px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #999
}

.school-base-info-container .base-info-m ul.labels .rz-label-list {
    display: flex;
    align-items: center;
    flex-wrap: nowrap
}

.school-base-info-container .base-info-m ul.labels .rz-label-list>i {
    margin-right: 8px;
    width: 111px;
    height: 26px;
    background-size: 100%
}

.school-base-info-container .base-info-m ul.labels .rz-label-list>i.gsrz {
    background-image: url("../../assets/files/f8985988ce4ba93bfac067cbc4c268a8.png")
}

.school-base-info-container .base-info-m ul.labels .rz-label-list>i.jxrz {
    background-image: url("../../assets/files/178237079d77b481df28fdcd50412167.png")
}

.school-base-info-container .base-info-m ul.labels .rz-label-list>i:last-child {
    margin-right: 0
}

.school-base-info-container .base-info-m ul.labels>li {
    display: flex;
    align-items: center;
    margin-right: 8px
}

.school-base-info-container .base-info-m ul.labels>li>img {
    width: 18px;
    height: 18px
}

.school-base-info-container .base-info-m ul.labels>li>span {
    margin-left: 2px;
    color: #333;
    font-size: 13px
}

.school-base-info-container .base-info-m .score-student {
    margin-top: 16px;
    display: flex;
    align-items: center
}

.school-base-info-container .base-info-m .score-student .score-wrap {
    display: flex;
    align-items: center
}

.school-base-info-container .base-info-m .score-student .score-wrap>.star-w {
    width: 94px;
    height: 18px;
    background-image: url("../../assets/files/ac757806bbb56c265b92891390f1fdd1.svg");
    background-size: 90px 18px
}

.school-base-info-container .base-info-m .score-student .score-wrap>.star-w>.score {
    width: 0;
    height: 100%;
    background-image: url("../../assets/files/bcdfd6d7265f9333597fdf23cfca0e1b.svg");
    background-size: 94px 18px
}

.school-base-info-container .base-info-m .score-student .score-wrap>span {
    margin-left: 8px;
    font-size: 15px;
    line-height: 20px;
    color: #666
}

.school-base-info-container .base-info-m .score-student .student-wrap {
    margin-left: 40px;
    display: flex;
    align-items: center
}

.school-base-info-container .base-info-m .score-student .student-wrap>i {
    width: 18px;
    height: 18px;
    background-size: 100%;
    background-image: url("../../assets/files/5f57a85d96308b0c483e02af3a7585ab.png")
}

.school-base-info-container .base-info-m .score-student .student-wrap>span {
    margin-left: 8px;
    color: #666;
    font-size: 15px;
    line-height: 20px
}

.school-base-info-container .base-info-m .filed,
.school-base-info-container .base-info-m .sign-up-phone {
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: #666;
    font-size: 15px;
    line-height: 20px
}

.school-base-info-container .base-info-m .filed>span {
    max-width: 360px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.school-base-info-container .base-info-m .filed>b {
    cursor: pointer;
    font-weight: 400;
    margin-left: 2px;
    color: #00ACFF
}

.school-base-info-container .base-info-m .enroll-btn {
    cursor: pointer;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 36px;
    border-radius: 18px;
    background-color: #32406B;
    font-size: 16px;
    color: #fff
}

.school-base-info-container .base-info-m .enroll-btn:hover {
    background-color: #5771C1
}

.school-base-info-container .base-info-m .enroll-btn.black-gold {
    background: #ffcb73;
    color: #000
}

.school-base-info-container .base-info-r {
    height: 100%;
    width: 299px;
    display: flex;
    align-items: center
}

.school-base-info-container .base-info-r .split-border {
    margin-right: 80px;
    width: 1px;
    height: 136px;
    background-color: #e9e9e9
}

.school-base-info-container .base-info-r .qr-code-wrap {
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-base-info-container .base-info-r .qr-code-wrap .morenImg {
    width: 140px;
    height: 140px;
    background-image: url("../../assets/files/766c015bdd9e878641b6ea4c2d54417b.png");
    background-size: 100%
}

.school-base-info-container .base-info-r .qr-code-wrap .qr-code {
    width: 140px;
    height: 140px
}

.school-base-info-container .base-info-r .qr-code-wrap>span {
    margin-top: 2px;
    font-size: 14px;
    line-height: 19px;
    color: #666
}

.com-school-map-box .school-map-address {
    width: 1100px;
    height: 600px;
    margin-top: 10px
}

.school-activity-container {
    width: 100%
}

.school-activity-container>ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}

.school-activity-container>ul>li {
    margin-left: 42px;
    width: 375px;
    height: 223px;
    display: flex;
    flex-direction: column;
    background-size: 100% 100%
}

.school-activity-container>ul>li:first-child {
    margin-left: 0
}

.school-activity-container>ul>li.activity-bg1 {
    background-image: url("../../assets/files/f02e42515fca76ba45d829aa2a1cabb6.png")
}

.school-activity-container>ul>li.activity-bg1 .activity-content .ctn-r .action-btn {
    color: #FD4A21
}

.school-activity-container>ul>li.activity-bg2 {
    background-image: url("../../assets/files/c16f9b806f2a00519feab32d2e204d29.png")
}

.school-activity-container>ul>li.activity-bg2 .activity-content .ctn-r .action-btn {
    color: #444B60
}

.school-activity-container>ul>li.activity-bg3 {
    background-image: url("../../assets/files/aa6dc7b2ec6af624e13d3d807a80cd36.png")
}

.school-activity-container>ul>li.activity-bg3 .activity-content .ctn-r .action-btn {
    color: #BA9967
}

.school-activity-container>ul>li .activity-title {
    margin: 22px 0;
    font-size: 28px;
    line-height: 38px;
    color: #41464F;
    text-align: center
}

.school-activity-container>ul>li .activity-content {
    display: flex;
    flex: 1
}

.school-activity-container>ul>li .activity-content .ctn-l {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center
}

.school-activity-container>ul>li .activity-content .ctn-l .count {
    display: flex;
    align-items: flex-end;
    color: #fff
}

.school-activity-container>ul>li .activity-content .ctn-l .count>b {
    font-size: 50px;
    line-height: 65px;
    font-weight: 400
}

.school-activity-container>ul>li .activity-content .ctn-l .count>span {
    margin-left: 2px;
    margin-bottom: 6px;
    font-size: 24px;
    line-height: 33px
}

.school-activity-container>ul>li .activity-content .ctn-l>p {
    margin-bottom: 33px;
    font-size: 22px;
    line-height: 32px;
    color: #fff
}

.school-activity-container>ul>li .activity-content .ctn-r {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 50px
}

.school-activity-container>ul>li .activity-content .ctn-r .action-btn {
    cursor: pointer;
    margin-bottom: 33px;
    width: 122px;
    height: 43px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    background-color: #fff
}

.school-activity-container>ul>li .activity-content .ctn-r .original {
    margin-bottom: 11px;
    font-size: 22px;
    line-height: 30px;
    color: #9B9DA5;
    text-decoration: line-through
}

.school-course-container ul.course-list {
    display: flex;
    flex-wrap: wrap;
    width: 1200px
}

.school-course-container ul.course-list>li:last-child {
    border-bottom: 1px solid #eaeaea
}

.school-course-container ul.course-list>li.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 30px 0;
    border-top: 1px solid #eaeaea
}

.school-course-container ul.course-list>li.row .course-c {
    margin-left: 50px;
    flex: 1;
    display: flex;
    flex-direction: column
}

.school-course-container ul.course-list>li.row .course-c .course-name {
    margin-top: 0;
    font-weight: 700;
    color: #333;
    font-size: 24px;
    line-height: 31px
}

.school-course-container ul.course-list>li.row .course-c .course-label {
    color: #333;
    margin-top: 10px;
    display: flex;
    align-items: center
}

.school-course-container ul.course-list>li.row .course-c .course-label>span {
    font-size: 16px;
    line-height: 21px;
    padding: 0 12px;
    border-left: 1px solid #eaeaea
}

.school-course-container ul.course-list>li.row .course-c .course-label>span:first-child {
    border-left: none;
    padding-left: 0
}

.school-course-container ul.course-list>li.row .course-c .course-label>span:last-child {
    padding-right: 0
}

.school-course-container ul.course-list>li.row .course-c .course-desc {
    margin-top: 13px;
    color: #666;
    font-size: 14px;
    line-height: 24px
}

.school-course-container ul.course-list>li.row .course-r {
    margin-top: 43px;
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-course-container ul.course-list>li.row .course-r .course-price {
    margin-top: 0
}

.school-course-container ul.course-list>li.row .course-r .course-price>b {
    font-size: 28px;
    line-height: 30px
}

.school-course-container ul.course-list>li.row .course-r .course-price>span {
    font-size: 24px;
    line-height: 28px
}

.school-course-container ul.course-list>li.column {
    margin-right: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 286px;
    height: 375px;
    border: 1px solid #eaeaea
}

.school-course-container ul.course-list>li.column:last-child {
    margin-right: 0
}

.school-course-container ul.course-list>li .course-img {
    width: 284px;
    height: 192px;
    background-size: 100% 100%
}

.school-course-container ul.course-list>li .course-img>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.school-course-container ul.course-list>li .course-name {
    margin-top: 12px;
    color: #333;
    font-size: 17px;
    line-height: 22px;
    font-weight: 700
}

.school-course-container ul.course-list>li .course-label {
    margin-top: 8px;
    display: flex;
    align-items: center;
    color: #666
}

.school-course-container ul.course-list>li .course-label>span {
    font-size: 13px;
    line-height: 17px;
    padding: 0 10px;
    border-left: 1px solid #eaeaea
}

.school-course-container ul.course-list>li .course-label>span:first-child {
    border-left: none;
    padding-left: 0
}

.school-course-container ul.course-list>li .course-label>span:last-child {
    padding-right: 0
}

.school-course-container ul.course-list>li .course-price {
    display: flex;
    align-items: flex-end;
    margin-top: 12px
}

.school-course-container ul.course-list>li .course-price>b {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #FF4A40
}

.school-course-container ul.course-list>li .course-price>span {
    margin-left: 4px;
    color: #999;
    font-size: 18px;
    line-height: 21px;
    text-decoration: line-through
}

.school-course-container ul.course-list>li .course-btn {
    cursor: pointer;
    margin-top: 20px;
    width: 180px;
    height: 45px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    background-color: #32406B
}

.school-course-container ul.course-list>li .course-btn:hover {
    background-color: #5771C1
}

.school-course-container ul.course-list>li .course-btn.black-gold {
    background: #ffcb73;
    color: #000
}

.school-coach-container {
    position: relative;
    width: 1200px
}

.school-coach-container .swiper-button-next,
.school-coach-container .swiper-button-prev {
    color: #CCC
}

.school-coach-container .swiper-button-next {
    right: -70px
}

.school-coach-container .swiper-button-prev {
    left: -70px
}

.school-coach-container .swiper-container {
    width: 100%;
    height: 200px
}

.school-coach-container .coach-list li {
    display: flex;
    align-items: center;
    height: 198px;
    background-color: #fff;
    border: 1px solid #eaeaea
}

.school-coach-container .coach-list li.no-m-r {
    margin-right: 0
}

.school-coach-container .coach-list li.no-m-t {
    margin-top: 0
}

.school-coach-container .coach-list li .coach-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 110px;
    height: 110px;
    background-image: url("../../assets/files/5fcd8c4d1a9cbacd748e1b3b02a81e0e.png");
    background-size: 100% 100%
}

.school-coach-container .coach-list li .coach-l {
    margin-left: 20px;
    width: 118px;
    height: 158px
}

.school-coach-container .coach-list li .coach-l>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.school-coach-container .coach-list li .coach-r {
    margin-left: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.school-coach-container .coach-list li .coach-r .name {
    display: flex;
    align-items: center;
    margin-top: 8px
}

.school-coach-container .coach-list li .coach-r .name b {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #333
}

.school-coach-container .coach-list li .coach-r .name i {
    margin-left: 8px;
    width: 14px;
    height: 18px;
    background-size: 100% 100%;
    display: block
}

.school-coach-container .coach-list li .coach-r .name i.none {
    display: none
}

.school-coach-container .coach-list li .coach-r .name i.jx-rz {
    width: 41px;
    height: 14px;
    background-image: url("../../assets/files/20a96064687dbbd43adb63a2ace84d1c.png")
}

.school-coach-container .coach-list li .coach-r .name i.five-star {
    background-image: url("../../assets/files/2de9504bddbabe9dff0a1402915a86f9.png")
}

.school-coach-container .coach-list li .coach-r .name i.gold-coach {
    background-image: url("../../assets/files/4f2bc656f2066842a30d10ef5a0979c5.png")
}

.school-coach-container .coach-list li .coach-r .name i.jx-star {
    background-image: url("../../assets/files/1222f2200ae69ce4a1e98ea22e37f321.png")
}

.school-coach-container .coach-list li .coach-r .name span {
    margin-left: 4px;
    color: #333;
    font-size: 12px;
    line-height: 1
}

.school-coach-container .coach-list li .coach-r .teach-age {
    margin-top: 10px;
    display: flex;
    align-items: center
}

.school-coach-container .coach-list li .coach-r .teach-age>span {
    font-size: 15px;
    line-height: 20px;
    color: #666
}

.school-coach-container .coach-list li .coach-r .teach-age>b {
    font-size: 0;
    width: 1px;
    height: 15px;
    background-color: #eaeaea;
    margin: 0 10px
}

.school-coach-container .coach-list li .coach-r .score-wrap {
    margin-top: 10px;
    display: flex;
    align-items: center
}

.school-coach-container .coach-list li .coach-r .score-wrap>.star-w {
    width: 94px;
    height: 18px;
    background-image: url("../../assets/files/44ae76c45216b219a4c02a166beed0a7.svg");
    background-size: 90px 18px
}

.school-coach-container .coach-list li .coach-r .score-wrap>.star-w>.score {
    width: 0;
    height: 100%;
    background-image: url("../../assets/files/d4bf1d53e1b315901527517a43881390.svg");
    background-size: 94px 18px
}

.school-coach-container .coach-list li .coach-r .score-wrap>span {
    margin-left: 8px;
    font-size: 15px;
    line-height: 20px;
    color: #666
}

.school-coach-container .coach-list li .coach-r .enroll-btn {
    cursor: pointer;
    margin-top: 24px;
    width: 110px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
    background-color: #32406B
}

.school-coach-container .coach-list li .coach-r .enroll-btn:hover {
    background-color: #5771C1
}

.school-coach-container .coach-list li .coach-r .enroll-btn.black-gold {
    background: #ffcb73;
    color: #000
}

.school-train-container li,
.school-train-container li .li-r .name {
    display: flex;
    align-items: center
}

.school-train-container {
    width: 1200px;
    position: relative
}

.school-train-container .swiper-button-next,
.school-train-container .swiper-button-prev {
    color: #CCC
}

.school-train-container .swiper-button-next {
    right: -70px
}

.school-train-container .swiper-button-prev {
    left: -70px
}

.school-train-container .swiper-container1 {
    width: 100%;
    height: 150px;
    overflow: hidden
}

.school-train-container li {
    height: 100%;
    border: 1px solid #eaeaea;
    padding: 20px
}

.school-train-container li.no-m-p {
    margin-top: 0
}

.school-train-container li .li-l {
    width: 160px;
    height: 110px
}

.school-train-container li .li-l>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.school-train-container li .li-r {
    flex: 1;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.school-train-container li .li-r .name>b {
    color: #333;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.school-train-container li .li-r .name>b.nearest,
.school-train-container li .li-r .name>b.own {
    max-width: 200px
}

.school-train-container li .li-r .name>span {
    margin-left: 8px;
    width: 44px;
    height: 20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #fff
}

.school-train-container li .li-r .name>span.nearest {
    background-color: #7E8DA6
}

.school-train-container li .li-r .name>span.own {
    background-color: #3190E8
}

.school-train-container li .li-r .address {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    max-width: 172px;
    overflow: hidden;
    height: 40px
}

.school-train-container li .li-r .distance {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #999
}

.school-train-container .more {
    cursor: pointer;
    position: absolute;
    top: -60px;
    right: 0;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #666
}

.school-photo-container {
    display: flex;
    justify-content: center;
    width: 1200px
}

.school-photo-container .photo-wrap-wrap {
    width: 1200px;
    white-space: nowrap;
    overflow: hidden
}

.school-photo-container .photo-wrap {
    width: 1200px;
    display: flex;
    position: relative;
    flex-direction: column
}

.school-photo-container .photo-wrap .more-content {
    cursor: pointer;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #666;
    line-height: 26px;
    position: absolute;
    right: 0;
    top: -60px
}

.school-photo-container .photo-wrap .more-content:hover {
    color: #5771C1
}

.school-photo-container .photo-img {
    position: relative;
    width: 285px;
    height: 187px;
    margin-right: 20px;
    display: inline-block;
    cursor: pointer
}

.school-photo-container .photo-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.school-photo-container .photo-img.photo-video:before {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: url("../../assets/files/04865005fd32d83dfeca13193bf1f4fc.png") left center no-repeat;
    background-size: 100% 100%
}

.school-photo-container .photo-view-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 10000
}

.school-photo-container .photo-view-container.show {
    display: block
}

.school-photo-container .photo-view-container.hide {
    display: none
}

.school-photo-container .photo-view-container .view-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7)
}

.school-photo-container .photo-view-container .view-content {
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 980px;
    height: 520px;
    border-radius: 6px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.school-photo-container .photo-view-container .content-top {
    display: flex;
    justify-content: flex-end;
    height: 50px
}

.school-photo-container .photo-view-container .content-top>span {
    cursor: pointer;
    width: 34px;
    height: 34px;
    font-size: 30px;
    text-align: center;
    line-height: 34px
}

.school-photo-container .photo-view-container .content-top>span:hover {
    background-color: #eaeaea
}

.school-photo-container .photo-view-container .content-center {
    display: flex
}

.school-photo-container .photo-view-container .content-center .view-large {
    margin-left: 20px;
    width: 550px;
    height: 412px;
    background: url("../../assets/files/19656928b930e7eea9cc0a721e590293.png") center no-repeat;
    background-size: 40%
}

.school-photo-container .photo-view-container .content-center .view-large>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.school-photo-container .photo-view-container .content-center .view-large>video {
    outline: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    background-color: #000
}

.school-photo-container .photo-view-container .content-center .view-small {
    display: flex;
    flex-direction: column;
    height: 412px;
    width: 370px;
    margin-left: 20px
}

.school-photo-container .photo-view-container .content-center .view-small .view-tab {
    width: 370px;
    height: 41px;
    display: flex;
    background-color: #F2F3F7
}

.school-photo-container .photo-view-container .content-center .view-small .view-tab .tab {
    height: 100%;
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center
}

.school-photo-container .photo-view-container .content-center .view-small .view-tab .tab>span {
    cursor: pointer;
    font-size: 18px;
    color: #666
}

.school-photo-container .photo-view-container .content-center .view-small .view-tab .tab.active>span {
    color: #5771C1
}

.school-photo-container .photo-view-container .content-center .view-small .view-tab .tab.active .tab-line {
    display: block
}

.school-photo-container .photo-view-container .content-center .view-small .view-tab .tab .tab-line {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 55px;
    height: 2px;
    background-color: #5771C1
}

.school-photo-container .photo-view-container .content-center .view-small .image-type-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: #FFF
}

.school-photo-container .photo-view-container .content-center .view-small .image-type-list>span {
    cursor: pointer;
    margin-top: 10px;
    padding: 6px 10px;
    font-size: 14px;
    background-color: #F3F7F7;
    border-radius: .26rem;
    margin-right: 10px;
    color: #666
}

.school-photo-container .photo-view-container .content-center .view-small .image-type-list>span.active {
    color: #5771C1;
    background-color: #F2F3F7
}

.school-photo-container .photo-view-container .content-center .view-small .view-img-list {
    width: 100%;
    flex: 1;
    height: 100%;
    margin-top: 12px;
    overflow-y: auto;
    overflow-x: hidden
}

.school-photo-container .photo-view-container .content-center .view-small .view-img-list ul {
    display: none;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-right: -10px
}

.school-photo-container .photo-view-container .content-center .view-small .view-img-list ul.active {
    display: flex
}

.school-photo-container .photo-view-container .content-center .view-small .view-img-list ul li {
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
    width: 80px;
    height: 60px;
    box-sizing: border-box
}

.school-photo-container .photo-view-container .content-center .view-small .view-img-list ul li.media-video {
    position: relative
}

.school-photo-container .photo-view-container .content-center .view-small .view-img-list ul li.media-video:before {
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: url("../../assets/files/04865005fd32d83dfeca13193bf1f4fc.png") left center no-repeat;
    background-size: 100% 100%
}

.school-photo-container .photo-view-container .content-center .view-small .view-img-list ul li>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.school-photo-container .photo-view-container .content-center .view-small .view-img-list ul li.active {
    border: 2px solid #37B5F8
}

.school-photo-container .photo-view-container .content-bottom {
    margin-top: 10px;
    display: flex;
    justify-content: center
}

.school-photo-container .photo-view-container .content-bottom span {
    font-size: 18px;
    line-height: 30px;
    color: #666
}

.school-no-data-container {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-no-data-container .img-wrap {
    width: 279px;
    height: 171px;
    background-image: url("../../assets/files/925d0bb4eb43f74e0d7a4822959cc0e5.png");
    background-size: 100% 100%
}

.school-no-data-container p {
    margin-top: 16px;
    font-size: 14px;
    color: #999;
    line-height: 20px
}

.school-enroll-container {
    display: flex;
    width: 1200px;
    background-color: #fff;
    align-items: center
}

.school-enroll-container .enroll-l {
    margin-left: 37px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-enroll-container .enroll-l .enroll-l-title {
    margin-left: 66px;
    margin-top: 50px;
    font-size: 20px;
    line-height: 28px;
    color: #333;
    font-weight: 700
}

.school-enroll-container .enroll-l>.com-enroll-pannel .input-box>form>.row.row-sms>.btn-get-sms {
    background: #32406b;
    font-size: 16px;
    color: #fff
}

.school-enroll-container .enroll-l>.com-enroll-pannel .input-box>form>.row3 {
    display: none
}

.school-enroll-container .enroll-l>.com-enroll-pannel .input-box>form>.row.row5 {
    margin-bottom: 0
}

.school-enroll-container .enroll-l>.com-enroll-pannel .input-box>form>.row.row5>button {
    margin-left: 65px;
    width: 452px;
    height: 50px;
    background: #32406b;
    border-radius: 4px;
    font-size: 18px;
    color: #fff
}

.school-enroll-container .enroll-l>.enroll-l-titletip {
    margin-left: 66px;
    margin-top: 12px;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 50px
}

.school-enroll-container .enroll-r {
    margin-left: 160px;
    width: 380px;
    height: 430px;
    padding: 0 40px 50px;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    display: flex;
    flex-direction: column;
    align-items: center
}

.school-enroll-container .enroll-r>.enroll-r-title {
    margin-top: 40px;
    margin-bottom: 8px;
    color: #333;
    font-size: 17px;
    line-height: 24px
}

.school-enroll-container .enroll-r p {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #666
}

.school-enroll-container.black-gold {
    background: #38383a
}

.school-enroll-container.black-gold .enroll-l .enroll-l-title {
    color: #ffdcaa
}

.school-enroll-container.black-gold .enroll-l .row {
    color: #ccbba1
}

.school-enroll-container.black-gold .enroll-l .row input {
    background-color: transparent;
    border: 1px solid #4c4c4c;
    color: #fff
}

.school-enroll-container.black-gold .enroll-l .row .btn-get-sms,
.school-enroll-container.black-gold .enroll-l .row .submit-btn {
    background: #ffcb73 !important;
    color: #000 !important
}

.school-enroll-container.black-gold .enroll-r {
    border: none;
    background: 0 0
}

.school-enroll-container.black-gold .enroll-r p {
    color: #ccbba1
}

.school-enroll-container.black-gold .enroll-r .enroll-r-title {
    color: #ffdcaa
}

.school-comment-container .static-w .item-left .score,
.school-comment-container .static-w .item-right {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.school-comment-container {
    width: 1200px
}

.school-comment-container .top-w {
    width: 100%;
    height: 262px;
    border: 1px solid #eaeaea
}

.school-comment-container .static-w {
    padding-top: 50px;
    text-align: center
}

.school-comment-container .static-w .item-left {
    display: inline-block;
    vertical-align: middle;
    width: 400px
}

.school-comment-container .static-w .item-left .score {
    line-height: 65px;
    font-size: 16px
}

.school-comment-container .static-w .item-left .score .big {
    font-size: 48px
}

.school-comment-container .static-w .item-left .bfb {
    height: 30px;
    margin: 5px 0
}

.school-comment-container .static-w .item-left .total {
    line-height: 22px;
    margin-top: 20px;
    color: #999
}

.school-comment-container .static-w .item-left .star-w {
    width: 151px;
    background: url("../../assets/files/7490a36a4e05e14526042d98aaeaac5a.png") left center no-repeat;
    background-size: 100%
}

.school-comment-container .static-w .item-left .star-w .star {
    background: url("../../assets/files/ac9fd9c83553453faa6c4e796fa95208.png") left center no-repeat
}

.school-comment-container .static-w .fgx {
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 120px;
    margin-top: 5px;
    background-color: #e9e9e9
}

.school-comment-container .static-w .item-right {
    display: inline-block;
    vertical-align: middle;
    width: 400px;
    font-size: 16px
}

.school-comment-container .static-w .item-right>.row {
    height: 22px;
    line-height: 22px;
    margin: 20px 0 0 90px;
    text-align: left
}

.school-comment-container .static-w .item-right>.row .lable {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 15px;
    text-align: right
}

.school-comment-container .static-w .item-right>.row .score {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px
}

.school-comment-container .star-w {
    display: inline-block;
    vertical-align: top;
    width: 101px;
    height: 100%;
    background: url("../../assets/files/dc1e063f00441849360a2d6e45b44857.png") left center no-repeat;
    background-size: 100%
}

.school-comment-container .star-w .star {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../../assets/files/c749fef66500bdb1147c829385afe613.png") left center no-repeat
}

.com-dianping-list>ul>li {
    padding: 25px 0;
    border-bottom: 1px solid #e9e9e9
}

.com-dianping-list>ul>li>.item-left {
    float: left
}

.com-dianping-list>ul>li>.item-left .img-w {
    border-radius: 50%;
    display: block;
    width: 60px;
    height: 60px;
    overflow: hidden
}

.com-dianping-list>ul>li>.item-left .img-w img {
    display: block;
    width: 60px;
    height: auto
}

.com-dianping-list>ul>li>.item-left .img-w mip-img {
    display: inline-block
}

.com-dianping-list>ul>li>.item-left .img-w mip-img img {
    min-width: inherit
}

.com-dianping-list>ul>li>.item-left .floor-name {
    text-align: center;
    margin-top: 10px;
    color: #999
}

.com-dianping-list>ul>li>.item-right {
    margin-left: 80px
}

.com-dianping-list>ul>li>.item-right>.content-top {
    height: 25px;
    line-height: 25px
}

.com-dianping-list>ul>li>.item-right>.content-top .date {
    color: #999
}

.com-dianping-list>ul>li>.item-right>.content-top .author-name {
    font-size: 16px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-dianping-list>ul>li>.item-right .content,
.com-dianping-list>ul>li>.item-right .item-static {
    font-size: 14px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-dianping-list>ul>li>.item-right>.content-top .city {
    margin-left: 30px;
    color: #999
}

.com-dianping-list>ul>li>.item-right .item-static .col {
    display: inline-block;
    vertical-align: middle;
    width: 300px;
    height: 22px;
    line-height: 22px;
    margin: 15px auto
}

.com-dianping-list>ul>li>.item-right .item-static .col .lable {
    color: #999;
    padding-right: 15px
}

.com-dianping-list>ul>li>.item-right .item-static .col .score {
    padding-left: 15px
}

.com-dianping-list>ul>li>.item-right .content {
    margin: 10px 0;
    line-height: 28px
}

.com-dianping-list>ul>li>.item-right .content.quote {
    background-color: #f5f5f5;
    border: 1px solid #e9e9e9;
    padding: 10px 25px
}

.com-dianping-list>ul>li>.item-right .content-bottom {
    line-height: 25px
}

.com-dianping-list>ul>li>.item-right .content-bottom .huifu {
    min-width: 16px;
    height: 25px;
    background: url("../../assets/files/2926453bd851419f7d38cfa1794bab3a.png") left center no-repeat;
    background-size: auto 16px;
    padding-left: 20px
}

.com-dianping-list>ul>li>.item-right .content-bottom .zan {
    margin-right: 20px;
    background: url("../../assets/files/feb58db11dda499e22c7eacbad095494.png") left center no-repeat;
    background-size: auto 16px;
    padding-left: 20px
}

.com-dianping-list .star-w {
    display: inline-block;
    vertical-align: top;
    width: 101px;
    height: 100%;
    background: url("../../assets/files/3b6882c0d0d051992448083c8dfc455a.png") left center no-repeat;
    background-size: 100%
}

.com-dianping-list .star-w .star {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../../assets/files/4a20e549891ab4ec7098dae999de9c8d.png") left center no-repeat
}

.school-footer-container {
    background-color: #222;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.school-footer-container>p {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #9a9a9a
}

.school-footer-container>p:last-child {
    margin-bottom: 0
}
</style>