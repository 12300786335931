
import { defineComponent, toRefs, Ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

import SwiperCore, {Navigation} from 'swiper';
SwiperCore.use([Navigation]);

import {useCoachList} from '@/compositions/training-school';

export default defineComponent({

    props: {
        sid: Number
    },

    components: {
        Swiper,
        SwiperSlide
    },

    setup: (props) => {

        const {sid} = toRefs(props);

        const coachListSetup = useCoachList(sid as Ref<number>);

        return coachListSetup;
    },

    data: function () {
        return {
            swiper: null
        };
    },

    methods: {
        setSwiper: function (swiper) {
            this.swiper = swiper;
        },

        swiperPrev: function () {
            this.swiper.slidePrev();
        },

        swiperNext: function () {
            this.swiper.slideNext();
        },

        consult: function (coach) {
            this.$emit('on-consult', coach);
        }
    }
});
