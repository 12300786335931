<template>
    <div class="school-course-container">
        <ul class="course-list">
            <li class="course column" v-for="item in classList" :key="item.id">
                <div class="course-img">
                    <img :src="item.image" alt="">
                </div>
                <div class="course-name">{{item.name}}</div>
                <div class="course-label">
                    <span>{{item.multi_user ? '多人一车' : '一人一车'}}</span>
                    <span v-if="item.pickup">接送</span>
                    <span>{{item.schedule}}</span>
                </div>
                <div class="course-price"><b>￥{{item.charge}}</b></div>
                <div class="course-btn " @click="consult(item)">免费咨询</div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';

import {useTrainingClassList} from '@/compositions/training-school';

export default defineComponent({

    props: {
        sid: Number
    },

    components: {

    },

    setup: (props) => {

        const {sid} = toRefs(props);

        return useTrainingClassList(sid as Ref<number>);
    },

    methods: {
        consult: function (item) {
            this.$emit('on-consult', item);
        }
    }
});
</script>