
import { defineComponent } from 'vue';

import VerifyCode from '@/mixins/verify-code';
import {enroll} from '@/api/training';
import {getCodeApi, registerVerify} from '@/api/user';

export default defineComponent({

    components: {

    },

    mixins: [VerifyCode],

    props: {
        sid: Number
    },

    data: function () {

        return {
            name: '',
            tel: '',
            address: '',
            smsCode: '',
            keyCode: '',
            acceptPolicy: false
        };
    },

    mounted: function () {
        this.getCode();
    },

    methods: {

        getCode() {
            getCodeApi()
                .then(res => {
                    this.keyCode = res.data.key;
                })
                .catch(res => {
                    this.$toast(res);
                });
        },

        sendSmsCode: function () {

            if (!this.tel.trim() || !/^1\d{10}$/.test(this.tel.trim())) {
                this.$toast('请填写正确的手机号');
                return;
            }

            registerVerify({
                phone: this.tel,
                type: 'verify',
                key: this.keyCode,
                code: ''
            })
            .then(res => {
                this.$toast(res.msg);
                this.smsSendInterval();
            })
            .catch(res => {
                this.$toast(res);
                // if (res.data.status === 402) {
                //  this.codeUrl = `${VUE_APP_API_URL}/sms_captcha?key=${this.keyCode}`;
                //  this.isShowCode = true;
                // }
            });
        },

        submit: function () {

            let data = {
                sid: this.sid,
                name: this.name.trim(),
                tel: this.tel.trim(),
                address: this.address.trim(),
                smsCode: this.smsCode,
                smsCodeId: this.smsCodeId
            };

            // if (!this.acceptPolicy) {
            //     this.$toast('为同意隐私策略');
            //     return;
            // }

            if (!data.name) {
                this.$toast('请填写姓名');
                return;
            }

            if (!data.tel || !/^1\d{10}$/.test(data.tel)) {
                this.$toast('请填写正确的手机号');
                return;
            }

            if (!data.smsCode) {
                this.$toast('请填写验证码');
                return;
            }

            enroll(data).then((res) => {
                alert(res.msg);
            });
        }
    }
});
