<template>
    <div class="school-coach-container">

        <swiper class="coach-list" navigation :slidesPerView="3" @swiper="setSwiper">
            <swiper-slide v-for="(item, index) in coachList" :key="index">
                <a>
                    <li>
                        <div class="coach-l">
                            <img :src="item.avatar" alt="">
                        </div>
                        <div class="coach-r">
                            <div class="name"><b>{{item.name}}</b><i class="jx-rz"></i><span></span></div>
                            <div class="teach-age">
                                <span>教龄7年</span><b>|</b><span>学员{{item.students}}</span>
                            </div>
                            <div class="score-wrap">
                                <div class="star-w">
                                    <div class="score" :style="{width: item.rating / 5 + '%'}"></div>
                                </div><span>{{item.rating}}分</span>
                            </div>
                            <div class="enroll-btn " @click="consult(item)">免费咨询</div>
                        </div>
                        <div class="coach-bg"></div>
                    </li>
                </a>
            </swiper-slide>
        </swiper>

        <div class="swiper-button-prev" @click="swiperPrev"></div>
        <div class="swiper-button-next" @click="swiperNext"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

import SwiperCore, {Navigation} from 'swiper';
SwiperCore.use([Navigation]);

import {useCoachList} from '@/compositions/training-school';

export default defineComponent({

    props: {
        sid: Number
    },

    components: {
        Swiper,
        SwiperSlide
    },

    setup: (props) => {

        const {sid} = toRefs(props);

        const coachListSetup = useCoachList(sid as Ref<number>);

        return coachListSetup;
    },

    data: function () {
        return {
            swiper: null
        };
    },

    methods: {
        setSwiper: function (swiper) {
            this.swiper = swiper;
        },

        swiperPrev: function () {
            this.swiper.slidePrev();
        },

        swiperNext: function () {
            this.swiper.slideNext();
        },

        consult: function (coach) {
            this.$emit('on-consult', coach);
        }
    }
});
</script>