
import { defineComponent, toRefs, Ref } from 'vue';

import {useTrainingClassList} from '@/compositions/training-school';

export default defineComponent({

    props: {
        sid: Number
    },

    components: {

    },

    setup: (props) => {

        const {sid} = toRefs(props);

        return useTrainingClassList(sid as Ref<number>);
    },

    methods: {
        consult: function (item) {
            this.$emit('on-consult', item);
        }
    }
});
