
import { defineComponent, toRefs, Ref } from 'vue';

import {useSchoolDetail} from '@/compositions/training-school';
import SchoolClassList from '@/components/school/school-class-list.vue';
import SchoolComments from '@/components/school/school-comments.vue';
import SchoolEnrollForm from '@/components/school/enroll-form.vue';
import CoachList from '@/components/school/coach-list.vue';

export default defineComponent({

    props: {
        id: Number
    },

    components: {
        SchoolClassList,
        // SchoolComments,
        SchoolEnrollForm,
        CoachList
    },

    setup: (props) => {

        const {id} = toRefs(props);

        return useSchoolDetail(id as Ref<number>);
    },

    methods: {
        consult: function (item) {
            console.log(item);
        },

        consultCoach: function (coach) {
            console.log(coach);
        },

        consultClass: function (item) {
            console.log(item);
        }
    }
});
